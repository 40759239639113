import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/CheckIns.vue";

const routes = [
  //   {
  //     path: "/",
  //     name: "home",
  //     component: HomeView,
  //   },
  {
    path: "/checkins",
    name: "checkins",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Delmarva/Login.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/Delmarva/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("../views/Delmarva/Home.vue"),
      },
      {
        path: "users",
        name: "Users",
        component: () => import("../views/Delmarva/Users.vue"),
      },
      {
        path: "reports",
        name: "Reports",
        component: () => import("../views/Delmarva/Reports.vue"),
      },
      {
        path: "shirts",
        name: "Shirts",
        component: () => import("../views/Delmarva/Shirts.vue"),
      },
      {
        path: "files",
        name: "Files",
        component: () => import("../views/Delmarva/Files.vue"),
      },
      {
        path: "settings",
        name: "Settings",
        component: () => import("../views/Delmarva/Settings.vue"),
      },
      {
        path: "data",
        name: "Data",
        component: () => import("../views/Delmarva/ImportData.vue"),
      },
      {
        path: "check-ins",
        name: "CheckIns",
        component: () => import("../views/Delmarva/CheckIns.vue"),
      },
      {
        path: "files/view/:path",
        name: "FilePreview",
        components: {
          default: () => import("../views/Delmarva/Files.vue"),
          section: () => import("../views/Delmarva/ViewFilePreview.vue"),
        },
      },
    ],
  },
  {
    path: "/moondoes",
    name: "Moondoes",
    component: () => import("../views/Moondoes/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "MoondoesDashboard",
        component: () => import("../views/Moondoes/Menu.vue"),
      },
      {
        path: "orders",
        name: "MoondoesOrders",
        component: () => import("../views/Moondoes/Orders.vue"),
      },
      {
        path: "inventory",
        name: "MoondoesInventory",
        component: () => import("../views/Moondoes/Inventory.vue"),
      },
      {
        path: "stats",
        name: "MoondoesStats",
        component: () => import("../views/Moondoes/Stats.vue"),
      },
    ],
  },
  {
    path: "/snackshack",
    name: "snackshack",
    component: () => import("../views/SnackShack/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "SnackShackDashboard",
        component: () => import("../views/SnackShack/Menu.vue"),
      },
      {
        path: "orders",
        name: "SnackShackOrders",
        component: () => import("../views/SnackShack/Orders.vue"),
      },
      {
        path: "inventory",
        name: "SnackShackInventory",
        component: () => import("../views/SnackShack/Inventory.vue"),
      },
      {
        path: "stats",
        name: "SnackShackStats",
        component: () => import("../views/SnackShack/Stats.vue"),
      },
    ],
  },
  {
    path: "/craftshop",
    name: "craftshop",
    component: () => import("../views/CraftShop/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "CraftShopDashboard",
        component: () => import("../views/CraftShop/Menu.vue"),
      },
      {
        path: "orders",
        name: "CraftShopOrders",
        component: () => import("../views/CraftShop/Orders.vue"),
      },
      {
        path: "inventory",
        name: "CraftShopInventory",
        component: () => import("../views/CraftShop/Inventory.vue"),
      },
      {
        path: "stats",
        name: "CraftShopStats",
        component: () => import("../views/CraftShop/Stats.vue"),
      },
    ],
  },
  {
    path: "/misc",
    name: "Misc",
    component: () => import("../views/Misc/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "MiscDashboard",
        component: () => import("../views/Misc/Menu.vue"),
      },
      {
        path: "orders",
        name: "MiscOrders",
        component: () => import("../views/Misc/Orders.vue"),
      },
      {
        path: "inventory",
        name: "MiscInventory",
        component: () => import("../views/Misc/Inventory.vue"),
      },
      {
        path: "stats",
        name: "MiscStats",
        component: () => import("../views/Misc/Stats.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/login",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
