import { defineStore } from "pinia";
import { db } from "@/firebase";
import {
  collection,
  query,
  where,
  doc,
  onSnapshot,
  setDoc,
  deleteDoc,
} from "firebase/firestore";

// const unsub = onSnapshot(doc(db, "cities", "SF"), (doc) => {
//   const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
//   console.log(source, " data: ", doc.data());
// });

export const usePeopleStore = defineStore("people", {
  state: () => ({
    people: null,
    unsubscribe: null,
  }),
  getters: {
    all: (state) => {
      let list = [];
      for (let [key, val] of state.people) {
        list.push(val);
      }
      return list;
    },
    campers: (state) => {
      let list = [];
      for (let [key, val] of state.people) {
        if (val.assignedRole == "campers") list.push(val);
      }
      return list;
    },
    volunteers: (state) => {
      let list = [];
      for (let [key, val] of state.people) {
        if (val.group == "volunteer") list.push(val);
      }
      return list;
    },
  },
  actions: {
    init() {
      let x = this;
      if (x.unsubscribe != null) return;
      x.people = new Map();
      let d = new Date(),
        year = d.getFullYear();
      const q = query(collection(db, "people"), where("campYear", "==", year));
      x.unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          x.people.set(doc.id, data);
        });
      });
    },
    async updatePerson(p, data) {
      let x = this;
      if (p.id == null) alert("Must provide ID to update person.");
      let d = new Date();
      const docRef = doc(db, "people", p.id);
      let updatedData = {
        lastUpdated: d,
        ...data,
      };
      setDoc(docRef, updatedData, { merge: true });
      console.log(`Updated ${p.id}`, data);
    },
    async deletePerson(p) {
      let x = this;
      if (p.id == null) alert("Must provide ID to delete person.");
      const docRef = doc(db, "people", p.id);
      await deleteDoc(docRef);
      console.log(`deleted ${p.id}`);
    },
    find(id) {
      let x = this;
      return x.people.get(id);
    },
  },
});
