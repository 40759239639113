import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import DesignSystem from "../design-system/vue.js";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import { createPinia } from "pinia";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import * as stores from "@/stores";
import "material-symbols";

const auth = getAuth();

let app;
onAuthStateChanged(auth, async (user) => {
  if (!app) {
    const pinia = createPinia();
    app = createApp(App);
    DesignSystem.register(app);
    app.use(pinia).use(router).use(autoAnimatePlugin).mount("#app");

    if (user) {
      const { loadUser } = stores.useAuthStore();
      loadUser();
    } else {
      // User is signed out
      router.push("/login");
    }
  }
});
