<template>
  <Layout el="hm fixed">
    <Header>
        <List el="xsm" class="section">
          <input type="search" placeholder="Search..."
          :value="search" 
      @keypress="search = $event.target.value"
      @input="search = $event.target.value" el />
          <List el="column xsm" style="align-items:center;">
            <Btn @click="toggleListType">{{listType}}</Btn>
            <Btn @click="toggleStatus">{{status}}</Btn>
            <Text el="bold">{{filteredPeople.length}}</Text>
          </List>
        </List>
    </Header>
    <main el="main">
      <List el="xxsm" class="section checkinList">
      <!-- <List el="xxsm" class="section checkinList" v-auto-animate> -->
        <List el="md" v-for="item in filteredPeople" :key="item.id" @click="toggleActive(item)" :class="{active: selectedCamper.name == item.name, person: true, checkedIn: item.status == 'checked in'}">
          <List el="column-between xsm">
            <Text>{{item.name}}</Text>
            <List el="column xxsm" style="align-items:center;">
              <Text v-if="item.type == 'camper'">{{item.cabin}}</Text>
              <Text v-if="item.type == 'staff'">{{item.shirtSize}}</Text>
              <span class="material-symbols-outlined" v-if="item.notes != null && item.notes != ''">text_snippet</span>
            </List>
          </List>
          <List el="md" v-if="selectedCamper != null && selectedCamper.name == item.name">
            <List el="xxsm">
              <List el="column xxsm" style="align-items:center;" v-if="item.gender != null">
                <span class="material-symbols-outlined">{{item.gender}}</span>
                <Text el="bold" style="text-transform: uppercase;">{{item.group}}</Text>
              </List>
              <List el="column xxsm" style="align-items:center;">
                <span class="material-symbols-outlined">laundry</span>
                <Text el="bold" style="text-transform: uppercase;">{{item.shirtSize}}</Text>
              </List>
              <List el="column xxsm" style="align-items:center;">
                <span class="material-symbols-outlined">church</span>
                <Text el="bold" style="text-transform: uppercase;">{{item.church}}</Text>
              </List>
              <List el="column xxsm" style="align-items:center;" v-if="item.status == 'checked in'">
                <span class="material-symbols-outlined">schedule</span>
                <Text el="bold" style="text-transform: uppercase;">{{formatDate(item.lastUpdated)}}</Text>
              </List>
            </List>
            <textarea @click.stop el placeholder="Notes..." v-model="item.notes"></textarea>
            <List el="column-stretch xsm">
              <Btn @click.stop="updatePerson(item)">Save</Btn>
              <Btn el="2ry" v-if="item.status != null && item.status == 'checked in'" @click.stop="checkOut(item)">Check Out</Btn>
              <Btn v-else @click.stop="checkIn(item)">Check In</Btn>
            </List>
          </List>
        </List>
      </List>
    </main>
    <Modal title="Modal Component" ref="modal">
      <List>
        <Text>Paragraph</Text>
      </List>
      <List el="column xxsm" style="justify-content: end;">
        <Btn @click="$refs.modal.toggle()">Button</Btn>
      </List>
    </Modal>
  </Layout>
</template>

<script setup>
// @ is an alias to /src
import {reactive, ref, computed} from 'vue'
import {db} from '@/firebase'
import { collection, query, where, onSnapshot, addDoc, setDoc, doc } from "firebase/firestore";
// import gsap from 'gsap'
// import Flip from "gsap/Flip";
import {usePeopleStore} from "@/stores";
var sortBy = require('lodash/sortBy');
var forEach = require('lodash/forEach');
var filter = require('lodash/filter');

const store = usePeopleStore();
const peopleStore = usePeopleStore();
peopleStore.init();

// gsap.registerPlugin(Flip);
// console.log(store.state.campers)
const search = ref('');
const status = ref('checked out');
const selectedCamper = ref({
  name:''
});
const listType = ref('camper');
const staff = computed(()=> peopleStore.volunteers);

const toggleActive = (item) => {
  if(selectedCamper.value == item){
    clearActive();
  }else{
    selectedCamper.value = item;
  }
};
const clearActive = () => {
  selectedCamper.value = {
    name:''
  };
};
const checkIn = (item) => {
  item.status = 'checked in';
  clearActive();
  updatePerson(item);
  search.value = '';
};
const checkOut = (item) => {
  item.status = null;
  clearActive();
  updatePerson(item);
  search.value = '';
};
const toggleListType = () => {
  if(listType.value == 'camper') return listType.value = 'staff';
  if(listType.value == 'staff') return listType.value = 'camper';
};
const toggleStatus = () => {
  if(status.value == 'checked out') return status.value = 'checked in';
  if(status.value == 'checked in') return status.value = 'checked out';
};

const formatDate = (date) => {
  let d = new Date(date.seconds*1000);
  return d.toLocaleString();
}








// const addPerson = async () => {
//   forEach(staff.value, async (item) => {
//     try {
//       item.type = 'staff';
//       const docRef = await addDoc(collection(db, "people"), item);
//       console.log("Document written with ID: ", docRef.id);
//     } catch(err) {
//       console.log(err);
//     }
//   })
  
// }
const updatePerson = async (data) => {
    try {
      let d = new Date();
      data.lastUpdated = d;
      const docRef = doc(db, 'people', data.id);
      setDoc(docRef, data, { merge: true });
    } catch(err) {
      console.log(err);
    }
}
const people = ref(new Map());

const loadList = () => {
  const q = query(collection(db, "people"));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      data.id = doc.id;
      people.value.set(doc.id, data);
    });
    // console.log(people.value);
  });
}

loadList();

const filteredPeople = computed(()=> {
  let p = Array.from(people.value, ([name, value]) => value);
  let c = sortBy(p, ['name']);
  // let items = gsap.utils.toArray('.person');
  // const state = Flip.getState('.person');
  let list = filter(c, item => {
    if(status.value == 'checked out'){
      return `${item.name} ${item.cabin} ${item.church}`.toLowerCase().search(search.value.toLowerCase()) >= 0  && (item.status == status.value || item.status == null) && item.type == listType.value;
    }else{
      return `${item.name} ${item.cabin} ${item.church}`.toLowerCase().search(search.value.toLowerCase()) >= 0 && item.status == status.value && item.type == listType.value;
    }
  })
  return list;
});


</script>
<style lang="scss">
.section{
  max-width:600px;
  margin:auto;
  width:100%;
}
body{
  background-color:#f1f1f1;
}
[el^=main] *{
  user-select:none;
}
@media (max-width: 800px){
  [el^=layout][el*=fixed] {
      max-height: 100vh;
      overflow: auto;
  }
}

[el^=header]{
  padding: 20px !important;
}
[el^=main]{
  padding: 0 20px;
  padding-bottom:100px;
}
input{
  height:45px !important;
  padding: 0 24px !important;
  --bg-color: #fff !important;
  border-radius:120px !important;
  box-shadow: 0 3px 12px rgba(0,0,0,0.07) !important;
}
textarea{
  height:120px !important;
  padding: 12px !important;
  --bg-color: #f1f1f1 !important;
  border-radius:12px !important;
}
.checkinList > *{
  padding: 24px !important;
  // --bg-color: #fff !important;
  // box-shadow: 0 3px 12px rgba(0,0,0,0.07) !important;
  border-radius:12px !important;
  --border:3px solid #ddd;
  transition:0.2s;
  cursor: pointer;
}
.checkinList > *:hover,
.checkinList .active{
  --border:3px solid #2facee;
}
.checkinList .checkedIn{
  --bg-color:lightgreen;
  --border:3px solid green;
}
.checkinList .active{
  --bg-color:#fff;
}
</style>
