import { defineStore } from "pinia";
import { db } from "@/firebase";
import {
  collection,
  query,
  where,
  doc,
  onSnapshot,
  setDoc,
  deleteDoc,
  addDoc,
} from "firebase/firestore";

export const useTransactionsStore = defineStore("transactions", {
  state: () => ({
    trans: null,
    unsubscribe: null,
  }),
  getters: {
    all: (state) => {
      let list = [];
      for (let [key, val] of state.trans) {
        list.push(val);
      }
      return list;
    },
    moondoes: (state) => {
      let list = [];
      for (let [key, val] of state.trans) {
        if (val.shop == "moondoes") list.push(val);
      }
      return list;
    },
    craftshop: (state) => {
      let list = [];
      for (let [key, val] of state.trans) {
        if (val.shop == "craftshop") list.push(val);
      }
      return list;
    },
    snackshack: (state) => {
      let list = [];
      for (let [key, val] of state.trans) {
        if (val.shop == "snackshack") list.push(val);
      }
      return list;
    },
    misc: (state) => {
      let list = [];
      for (let [key, val] of state.trans) {
        if (val.shop == "misc") list.push(val);
      }
      return list;
    },
  },
  actions: {
    init() {
      let x = this;
      if (x.unsubscribe != null) return;
      x.trans = new Map();
      let d = new Date(),
        // year = d.getFullYear();
        year = 2023;
      const q = query(collection(db, "trans"), where("campYear", "==", year));
      x.unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          x.trans.set(doc.id, data);
        });
      });

      console.log("trans", x.trans);
    },
    async updateTrans(id, data) {
      let x = this;
      if (id == null) alert("Must provide ID to update person.");
      let d = new Date();
      const docRef = doc(db, "trans", id);
      let updatedData = {
        lastUpdated: d,
        ...data,
      };
      setDoc(docRef, updatedData, { merge: true });
      console.log(`Updated transaction ${id}`, data);
    },
    async deleteTrans(id) {
      let x = this;
      if (id == null) alert("Must provide ID to delete person.");
      const docRef = doc(db, "trans", id);
      await deleteDoc(docRef);
      x.trans.delete(id);
      console.log(`deleted transaction ${id}`);
    },
    async addTrans(shop, data) {
      let d = new Date();
      let year = d.getFullYear();
      let newTrans = {
        shop: shop,
        timestamp: d,
        lastUpdated: d,
        status: "pending",
        campYear: year,
        ...data,
      };
      const docRef = await addDoc(collection(db, "trans"), newTrans);
      console.log("Document written with ID: ", docRef.id);
    },
  },
});
