import { defineStore } from "pinia";
import { ref, computed } from "vue";
import router from "@/router";
let startsWith = require("lodash/startsWith");
let findKey = require("lodash/findKey");

export const useLayoutStore = defineStore("layout", {
  state: () => ({
    window: {
      width: 1366,
      timeout: null,
    },
    header: {
      accountDropdown: false,
    },
    nav: {
      active: "Dashboard",
      collapse: true,
      menu: {
        // Cards: {
        //   key: "Cards",
        //   label: "Credit Cards",
        //   icon: "credit_card",
        //   path: "/dashboard/my-cards",
        // },
        // Billing: {
        //   key: "Billing",
        //   label: "Billing",
        //   icon: "payments",
        //   path: "/dashboard/billing",
        // },
        Dashboard: {
          key: "Dashboard",
          label: "Camp",
          icon: "home",
          path: "/dashboard",
          permissions: ["admin"],
        },
        Files: {
          key: "Files",
          label: "Files",
          icon: "article",
          path: "/dashboard/files",
        },
        Users: {
          key: "Users",
          label: "Users",
          icon: "people",
          path: "/dashboard/users",
          permissions: ["admin"],
        },
        // Team: {
        //   label: "Talent",
        //   icon: "work",
        //   path: "/dashboard/team",
        // },
      },
    },
    section: {
      loading: false,
      active: "modules",
      collapse: true,
    },
  }),
  getters: {
    windowData: (state) => state.window,
    headerData: (state) => state.header,
    navData: (state) => state.nav,
    sectionData: (state) => state.section,
  },
  actions: {
    resizeWindowEvent() {
      let state = this;
      if (state.window.timeout != null) {
        clearTimeout(state.window.timeout);
      }
      state.window.timeout = setTimeout(function () {
        state.window.width = window.innerWidth;
      }, 100);
    },
    navTo(section) {
      let state = this;
      if (state.window.width < 600) {
        state.nav.collapse = !state.nav.collapse;
      }
      state.nav.active = section;
      router.push(state.nav.menu[section].path);
    },
    toggleAccountDropdown() {
      let state = this;
      state.header.accountDropdown = !state.header.accountDropdown;
    },
    toggleNavState() {
      let state = this;
      state.nav.collapse = !state.nav.collapse;
      if (state.window.width < 600) {
        state.section.collapse = true;
      }
    },
    collapseSection(val) {
      let state = this;
      state.section.collapse = val;
    },
    activateNavPath() {
      let state = this;
      let path = router.currentRoute.value.path;
      const key = findKey(state.nav.menu, ["path", path]);
      if (key != null) {
        state.nav.active = key;
      }
    },
  },
});
