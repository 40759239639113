import { defineStore } from "pinia";
import {
  getAuth,
  signInWithPopup,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
} from "firebase/auth";
import { ref, computed } from "vue";
import router from "@/router";
import API from "@/components/API";

const auth = getAuth();

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: null,
  }),
  getters: {
    userData: (state) => state.user,
    isAdmin: (state) => {
      if (state.userData == null) return false;
      return state.userData.permissions.includes("admin");
    },
  },
  actions: {
    googleSignIn() {
      let x = this;
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then(async (result) => {
          const user = result.user;
          x.loadUser();
        })
        .catch((error) => {
          const errorMessage = error.message;
          const email = error.email;
          console.log(email, errorMessage);
        });
    },
    async loadUser() {
      let x = this;
      if (auth.currentUser == null) return router.push("/login");
      if (x.user != null) return;
      const userData = await API.post("/auth/user/data");
      if (userData.success == false) return router.push("/login");
      if (userData.user == null) {
        // create a new user
        let res = await API.post("/auth/user/create", {
          blocked: true,
          displayName: auth.currentUser.displayName,
          uid: auth.currentUser.uid,
          email: auth.currentUser.email,
          photoURL: auth.currentUser.photoURL,
          permissions: [],
        });
        x.user = res.user;
      } else {
        x.user = userData.user;
      }
      console.log("Loaded User", x.user);
      let path = router.currentRoute.value.path;
      if (path == "/login") router.push("/dashboard");
    },
    signOut() {
      let x = this;
      signOut(auth)
        .then(() => {
          x.user = null;
          router.push("/login");
          // Sign-out successful.
        })
        .catch(() => {
          // An error happened.
        });
    },
  },
});
